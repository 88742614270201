import React from 'react';
import { Link } from 'react-router-dom';


function FuncCardMini(props) {
  return (
    <div className='my-3 mx-1 flex justify-center items-center flex-col'>
        <img src={props.cardSrc} alt={props.cardAlt} className='shadow-md w-36 lg:w-44'/>
        <p className='border-b-2 pb-3 text-center text-xs lg:text-sm font-semibold font-Nunito text-blue-900 dark:text-purple-100 mt-6'>Click {props.btnText} to explore {props.pTitle} projects</p>
        <Link to={props.link} className="w-28 lg:w-32 relative bottom-28 lg:bottom-36 inline-block font-medium rounded-lg text-xs lg:text-sm px-1 py-2 lg:px-3 lg:py-2 text-center text-white border border-white hover:border-gray-600 hover:bg-gradient-to-br from-blue-400 via-violet-500 to-pink-400 focus:outline-none focus:ring-2 focus:ring-violet-300  dark:focus:ring-yellow-900">{props.btnText}</Link>
    </div>
  )
}

export default FuncCardMini;