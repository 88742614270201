import React from "react";

function ResumeBody() {
  let webListStyle =
    "text-[9px] lg:text-xs font-Caladea inline-block p-1 lg:px-2 bg-[#0f2463] hover:bg-gradient-to-r from-purple-500 to-pink-500 cursor-pointer text-white rounded-lg lg:rounded-2xl mx-[2px] lg:mx-1";
  let UxListStyle =
    "text-[9px] lg:text-xs font-Caladea inline-block p-1 lg:px-2 rounded-lg lg:rounded-2xl my-[2px] mx-[2px] lg:mx-1 bg-blue-800 hover:bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 text-white hover:text-[#0f2463] cursor-pointer";
  let softStyle =
    "text-[9px] lg:text-xs font-Caladea inline-block p-1 lg:px-2 bg-purple-500 hover:bg-gradient-to-r from-purple-600 to-blue-500 cursor-pointer text-white rounded-2xl m-1";
  let fontH6 =
    "text-xs lg:text-xl tracking-wide lg:tracking-wider font-Raleway font-semibold lg:font-semibold uppercase text-[#0f2463] border-b lg:border-b-2 border-[#0f2463] py-1 my-2";

  let rectangleHead =
    "text-white uppercase text-sm mx-[2px] px-1 py-[2px] lg:text-lg lg:py-1 lg:px-2 tracking-wide lg:tracking-wider inline-block bg-[#0f2463] hover:bg-gradient-to-r from-blue-500 via-sky-600 to-purple-700 w-[90%] lg:w-[85%] font-Raleway font-semibold rounded-sm";
  return (
    <>
    {/* Left part of the Body  */}
      <div className="ResumeBody w-full flex">
        <div className="left-info w-[40%] bg-[#ecf4ff] rounded-lg lg:rounded-bl-2xl p-2 lg:p-4 ">
          <div className="technical-skills my-3">
            <h6 className={fontH6}>Technical skills</h6>
            <div className="WebDev-list">
              <h6 className="text-xs lg:text-base font-lato font-bold text-gray-500 mr-[1px] lg:my-1">
                Web Development &rArr; 
              </h6>
              <ul>
                <li className={webListStyle}>HTML5</li>
                <li className={webListStyle}>CSS3</li>
                <li className={webListStyle}>JavaScript</li>
                <li className={webListStyle}>React JS</li>
                <li className={webListStyle}>Tailwind CSS</li>
                <li className={webListStyle}>NPM</li>
                <li className={webListStyle}>Next JS</li>
                <li className={webListStyle}>Node JS</li>
                <li className={webListStyle}>SaaS</li>
                <li className={webListStyle}>Git & Github</li>
                <li className={webListStyle}>VS Code</li>
                <li className={webListStyle}>Bootstrap</li>
                <li className={webListStyle}>JS ES6 Syntaxt</li>
                <li className={webListStyle}>JQuery</li>
                <li className={webListStyle}>SEO</li>
                <li className={webListStyle}>Figma</li>
                <li className={webListStyle}>Canva</li>
                <li className={webListStyle}>Redux</li>
                <li className={webListStyle}>Wordpress Design</li>
                <li className={webListStyle}>Figma to HTML</li>
              </ul>
            </div>
            <div className="uxDesign">
              <h6 className="text-xs lg:text-base font-lato font-bold text-gray-500 mr-[1px] my-[2px] lg:my-1">
                UX Design &rArr;
              </h6>
              <ul>
                <li className={UxListStyle}>UI Design</li>
                <li className={UxListStyle}>User Experience Design</li>
                <li className={UxListStyle}>Web Design</li>
                <li className={UxListStyle}>Basic Graphic Design</li>
                <li className={UxListStyle}>Figma</li>
                <li className={UxListStyle}>Design Psychology</li>
              </ul>
            </div>
            <div className="learning skills">
              <h6 className="text-xs lg:text-base font-lato font-bold text-gray-500 mr-[1px] my-[2px] lg:my-1">
                Learning (/In Future Plan)&rArr;
              </h6>
              <ul>
                <li className={webListStyle}>MongoDB</li>
                <li className={webListStyle}>Express</li>
                <li className={webListStyle}>AWS</li>
                <li className={webListStyle}>Sanity.io</li>
                <li className={webListStyle}>Blockchain</li>
              </ul>
            </div>
          </div>
          <div className="soft-skills my-3">
            <h6 className={fontH6}>Soft Skills</h6>
            <ul>
              <li className={softStyle}>Team Management</li>
              <li className={softStyle}>Creative Thinking</li>
              <li className={softStyle}>Problem Solving</li>
              <li className={softStyle}>Project Planning</li>
              <li className={softStyle}>Effective Communication</li>
            </ul>
          </div>
          <div className="languages my-3">
            <h6 className={fontH6}>Languages</h6>
            <p className="text-[8px] inline-block m-[2px] px-[2px] lg:m-1 lg:px-1 font-Caladea text-[#0f2463] lg:text-sm">
              Bengali (Native)
            </p>
            <p className="text-[8px] inline-block m-[2px] px-[2px] lg:m-2 lg:px-1 font-Caladea text-[#0f2463] lg:text-sm">
              English
            </p>
            <p className="text-[8px] inline-block m-[2px] px-[2px] lg:m-2 lg:px-1 font-Caladea text-[#0f2463] lg:text-sm">
              Hindi
            </p>
          </div>
          <div className="hobby my-3">
            <h6 className={fontH6}>Hobbies</h6>
            <ul className="list-disc mx-2 lg:mx-4 my-1 lg:my-2 font-Caladea text-[9px] lg:text-sm text-[#0f2463]">
              <li>Book Reading</li>
              <li>Listening Podcasts</li>
              <li>Coding</li>
              <li>Imaginary Design</li>
            </ul>
          </div>
        </div>

        {/* Right part of the Body  */}
        <div className="right-info w-[60%] py-4 px-4">
          <div className="Experience">
            <div className="head lg:mx-1lg: my-2 flex justify-around">
              <div className="circle w-6 h-6 lg:w-9 lg:h-9 bg-[#0f2463] rounded-full"></div>
              <h3 className={rectangleHead}>Experience</h3>
            </div>
            {/* Front End Developer  */}
            <div className="work mx-1 py-1 text-[#1d3157]">
              <h6 className="text-xs lg:text-md font-semibold font-Lato my-[2px] lg:my-1">
              Freelance Front-end Developer | React JS Developer
              </h6>
              <div className="company flex justify-between text-[9px] lg:text-sm font-[600] my-1">
                <p>Fiverr | Upwork</p>
                <p>Dec 2021 - Sep 2022 (9 months)</p>
              </div>
              <li className="text-[8px] my-[2px] lg:text-xs lg:my-1">
              Worked with designers and back-end developers to implement visually appealing and functional websites and web applications.
              </li>
              <li className="text-[8px] my-[2px] lg:text-xs lg:my-1">
              Developed responsive designs using CSS and media queries, ensuring optimal viewing experiences on all devices.
              </li>
              <li className="text-[8px] my-[2px] lg:text-xs lg:my-1">
              Improved website performance and load times through optimization techniques and browser caching.
              </li>
              <li className="text-[8px] my-[2px] lg:text-xs lg:my-1">
              Developed and maintained complex web applications using React JS and Redux.
              </li>
              <li className="text-[8px] my-[2px] lg:text-xs lg:my-1">
              Improved website performance through the use of optimization techniques and code reusability.
              </li>
            </div>

            {/* Wordpress Developer  */}
            <div className="work mx-1 py-1 text-[#1d3157]">
              <h6 className="text-xs lg:text-md font-semibold font-Lato my-[2px] lg:my-1">
                Freelance WordPress Designer
              </h6>
              <div className="company flex justify-between text-[9px] lg:text-sm font-[600] my-1">
                <p>Fiverr | Upwork</p>
                <p>Sep 2021 - Nov 2021 (3 months)</p>
              </div>
              <li className="text-[8px] my-[2px] lg:text-xs lg:my-1">
                I have helped 3 clients to build their WordPress websites and
                increased their revenue up to 40% through SEO.
              </li>
            </div>
                
          </div>
          <div className="Projects">
            <div className="head mx-1 my-2 flex justify-around">
              <div className="circle w-6 h-6 lg:w-9 lg:h-9 bg-[#0f2463] rounded-full"></div>
              <h3 className={rectangleHead}>Projects</h3>
            </div>
            <div className="mx-1 py-[2px] lg:mx-2 lg:py-1 font-nunito text-[#0f2463]">
              <li className="text-[10px] my-1 lg:text-xs lg:my-2">
              <a className="underline" href="http://happysmile.pages.dev" target="_blank" rel="noopener noreferrer">Happy Smile Dental Clinic</a>: A medical website which I've designed (Figma) & developed the frontend part using modern web technologies.
              </li>
              <li className="text-[10px] my-1 lg:text-xs lg:my-2">
              <a className="underline" href="http://gymbros.pages.dev" target="_blank" rel="noopener noreferrer">Gym Bros</a>: A gym website which is build on React and Tailwind CSS as a framework. This website consist of React-Framer animation, React-scroll animations and many more.
              </li>
              <li className="text-[10px] my-1 lg:text-xs lg:my-2">
              Personal Portfolio Website: Developed a personal portfolio website to showcase my web development skills and previous projects. Site: <a href="https://skriponux.pages.dev" target="_blank" rel="noopener noreferrer">https://skriponux.pages.dev</a> 
              </li>
              <li className="text-[10px] my-1 lg:text-xs lg:my-2">
              Blog Website: Developed a responsive blog website with dashbaord system using Next JS.
              </li>
            </div>
          </div>
          <div className="Education">
            <div className="head mx-1 my-3 flex justify-around">
              <div className="circle w-6 h-6 lg:w-9 lg:h-9 bg-[#0f2463] rounded-full"></div>
              <h3 className={rectangleHead}>Education</h3>
            </div>
            <div className="education text-[#0f2463] ">
              <div className="gap-y-1 flex flex-col">
                <h6 className="text-xs font-semibold">Certificate in Front End Web Development</h6>
                <p className="text-[10px]">Rajdeep Dhakad University (Aug 2020 - Nov 2020)</p>
                <h6 className="text-xs font-semibold mt-2">B.Sc Microbiology (Hons.)</h6>
                <p className="text-[10px]">Vivekananda College - Burdwan University <br /> (July 2020 - june 2021)</p>
                <h6 className="text-xs font-semibold">Higher Secondary (HS)</h6>
                <p className="text-[10px]">Gantar BM High School (Passed out in 2020 with 84% Marks)</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResumeBody;
