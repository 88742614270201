import React from 'react';
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Lamborghini from "../Images/Lamborghini.png";
import GuesstheNumber from "../Images/GuesstheNumber.png";
import CuteSnake from "../Images/CuteSnake.png";
import Tictactoe from "../Images/Tictactoe.jpg";
import ProjectBox from "../Components/ProjectBox";

function Games() {
    const colorEffect = () => {
        document.getElementById("utility").style.color = "#050bab";
      };
      const colorNone = () => {
        document.getElementById("utility").style.color = "white";
      };
      return (
        <div>
          <div className="w-full h-[120vh] lg:h-screen">
            <div className="h-[120vh] lg:h-screen w-full bg-cover m-auto py-14 bg-[url('Images/UtilityBG.avif')] bg-no-repeat">
              <div
                className="lg:w-[50%] w-[85%] h-[15vh] mx-auto flex flex-col justify-center items-center bg-[#ffffff10] hover:bg-[#ffffff] transition-all ease-in shadow-2xl backdrop-blur-lg rounded-2xl border border-gray-400 hover:border-gray-700"
                onMouseOver={colorEffect}
                onMouseLeave={colorNone}
              >
                <h1
                  id="utility"
                  className="text-xl lg:text-3xl text-white text-center transition-all ease-in font-Nunito font-bold py-2"
                >
                  Games
                </h1>
                <p className="text-sm text-center text-green-300 hover:text-green-500 px-3">
                  Games are unavailable right now. For more Web Development projetcs please
                  <Link to={"/webdevelopment"}>
                    <u> check this. </u>
                  </Link>
                   These projects will be available in January 2023.
                </p>
              </div>
    
              <div className="w-[90%] mx-auto mt-12 flex justify-center gap-x-4 gap-y-6 flex-wrap">
                <ProjectBox projectName="Lambo Racer" logosrc={Lamborghini} type="Game"/>
                <ProjectBox projectName="Guess The Number" logosrc={GuesstheNumber} type="Game"/>
                <ProjectBox projectName="CuteSnake" logosrc={CuteSnake} type="Game"/>
                <ProjectBox projectName="Tic Tac Toe" logosrc={Tictactoe} type="Game"/>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
}

export default Games