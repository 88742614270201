import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./pages/Navbar.js";
import Home from "./pages/Home.js";
import Blog from "./pages/Blog.js";
import Contact from "./pages/Contact.js";
import Services from "./pages/Services.js";
import Resumepage from "./pages/Resumepage.js";
import About from "./pages/AboutProject.js";
import AllprojectTmpt from "./Components/AllprojectTmpt.js";
import WebDevelopment from "./pages/WebDevelopment.js";
import Website from "./pages/Website.js";
import Utilities from "./pages/Utilities.js";
import Games from "./pages/Games.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Services />} />
            <Route path="blog" element={<Blog />} />
            <Route path="resume" element={<Resumepage />} />
            <Route path="projects" element={<AllprojectTmpt />} />
            <Route path="websites" element={<Website/>} />
            <Route path="utility" element={<Utilities/>} />
            <Route path="games" element={<Games/>} />
            <Route path="webdevelopment" element={<WebDevelopment />} />
            <Route path="privacypolicy" element={<PrivacyPolicy/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
