import React from "react";
import ResumeBody from "./ResumeBody";
import ResumeHead from "./ResumeHead";
import { MdCloudDownload } from "react-icons/md";

function Resume() {
   // Function will execute on click of button
   const onButtonClick = () => {
    const fileURL = process.env.PUBLIC_URL + '/skripon_reactdeveloper.pdf';
    window.location.assign(fileURL);
  }
  return (
    <div className="w-full lg:w-[70%] mt-16 py-[18px] lg:m-auto animate-bgChange dark:animate-none dark:bg-gradient-to-br from-black to-[#312276] rounded-lg lg:rounded-3xl flex flex-col items-center shadow-2xl dark:border dark:border-gray-700">
      <p className="text-center pt-4 text-xl md:text-2xl lg:text-3xl font-Nunito font-bold text-white">
        Build on React JS and Tailwind CSS
      </p>
      <div className="resume w-[90%] lg:w-[85%] bg-white rounded-2xl my-4">
        <ResumeHead />
        <ResumeBody />
      </div>
      <button onClick={onButtonClick} className="hidden md:hidden lg:relative lg:inline-flex lg:items-center lg:justify-center p-0.5 my-3 lg:my-6 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800">
        <span className="relative flex text-white font-Lato font-semibold items-center  px-5 py-2.5 transition-all ease-in duration-75 bg-violet-600 dark:bg-purple-900 rounded-md group-hover:bg-opacity-0">
            <MdCloudDownload className="mr-3"/> Download Resume
        </span>
      </button>
      <button onClick={onButtonClick} className="lg:hidden relative inline-flex items-center justify-center p-0.5 my-3 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800">
        <span className="relative flex text-white font-Lato font-semibold items-center  px-5 py-2.5 transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0">
            <MdCloudDownload className="mr-3"/> Download Resume
        </span>
      </button>
    </div>
  );
}

export default Resume;
