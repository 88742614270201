import React from "react";
import Footer from "../Components/Footer";
// import {Outlet, Link} from "react-router-dom";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BsInstagram, BsGithub } from "react-icons/bs";
import { SiReplit } from "react-icons/si";

function Contact() {
  return (
    <>
      <div className="w-full h-[100vh]">
        {/* For Large Screen */}
        <div className="hidden md:hidden lg:block w-full h-full bg-cover m-auto py-20 bg-[url('Images/startman.avif')] bg-no-repeat saturate-100">
          <div className="w-[60%] h-[60vh] m-auto bg-[#ffffff10] shadow-2xl backdrop-blur-md rounded-xl border border-gray-600">
            <h1 className="text-3xl text-white font-Nunito font-bold text-center py-2">
              My Contact Details
            </h1>
            <hr className="mx-6 my-1" />
            <div className="flex justify-around my-10 font-Nunito">
              <div>
                <a href="https://www.linkedin.com/in/sk-ripon-a82a7b222/" target={"_blank"} rel="noreferrer" className="text-white flex gap-x-3 my-10  hover:text-blue-500 hover:cursor-pointer">
                  <FaLinkedinIn className="text-3xl" />
                  <p className="text-xl">Linkedin</p>
                </a>
                <a href="https://twitter.com/myself_ripon/" target={"_blank"} rel="noreferrer" className="text-white flex gap-x-3 my-10 hover:text-sky-400 hover:cursor-pointer">
                  <FaTwitter className="text-3xl" />
                  <p className="text-xl">Twitter</p>
                </a>
                <a href="https://www.instagram.com/ritsux.coder/" target={"_blank"} rel="noreferrer" className="text-white flex gap-x-3 my-10 hover:text-pink-700 hover:cursor-pointer">
                  <BsInstagram className="text-3xl" />
                  <p className="text-xl">Ritsux.Coder</p>
                </a>
              </div>
              <div>
                <a href="https://mail.google.com/mail/u/0/#inbox?compose=new" target={"_blank"} rel="noreferrer" className="text-white flex gap-x-3 my-10 hover:text-indigo-600 hover:cursor-pointer">
                  <MdEmail className="text-3xl" />
                  <p className="text-xl">Myselfripon20@gmail.com</p>
                </a>
                <a href="https://github.com/MyselfRipon20/" target={"_blank"} rel="noreferrer" className="text-white flex gap-x-3 my-10 hover:text-green-400 hover:cursor-pointer">
                  <BsGithub className="text-3xl" />
                  <p className="text-xl">Github</p>
                </a>
                <a href="https://replit.com/@MyselfRipon" target={"_blank"} rel="noreferrer" className="text-white flex gap-x-3 my-10 hover:text-amber-500 hover:cursor-pointer">
                  <SiReplit className="text-3xl" />
                  <p className="text-xl">Replit</p>
                </a>
              </div>
            </div>
            <p className="text-center text-gray-400 text-base font-Nunito">
              View live project code on Replit.
            </p>
          </div>
        </div>

        {/* For Small Screen Images/ */}
        <div className="block md:block lg:hidden w-full h-[100vh] bg-cover m-auto py-20 bg-[url('Images/aurora.avif')] bg-no-repeat saturate-100">
          <div className="w-[80%] h-[70vh] md:h-[50vh] m-auto bg-[#ffffff10] shadow-2xl backdrop-blur-md rounded-xl border border-gray-600">
            <h1 className="text-2xl text-white font-Nunito font-bold text-center py-2 dark:text-slate-300">
              My Contact Details
            </h1>
            <hr className="mx-3 my-1" />
            <div className=" flex flex-col items-start md:items-center my-6 px-8 font-Nunito">

              <a href="https://www.instagram.com/ritsux.coder/" target={"_blank"} rel="noreferrer" className="text-pink-700 hover:text-white flex items-center justify-center gap-x-5 my-4">
                <BsInstagram className="text-xl" />
                <p className="text-xl">Ritsux.Coder</p>
              </a>

              <a href="https://www.linkedin.com/in/sk-ripon-a82a7b222/" target={"_blank"} rel="noreferrer" className="text-blue-600 hover:text-white flex items-center justify-center gap-x-5 my-4 ">
                <FaLinkedinIn className="text-xl" />
                <p className="text-xl">Linkedin</p>
              </a>

              <a href="https://twitter.com/myself_ripon/" target={"_blank"} rel="noreferrer" className="text-sky-500 hover:text-white flex items-center justify-center gap-x-3 my-4">
                <FaTwitter className="text-xl" />
                <p className="text-xl">Twitter</p>
              </a>

              <a href="https://mail.google.com/mail/u/0/#inbox?compose=new" target={"_blank"} rel="noreferrer" className="text-indigo-400 hover:text-white flex items-center justify-center gap-x-3 my-4">
                <MdEmail className="text-xl" />
                <p className="text-xl">Email Me</p>
              </a>

              <a href="https://github.com/MyselfRipon20/" target={"_blank"} rel="noreferrer" className="text-green-300 hover:text-white flex items-center justify-center gap-x-3 my-4">
                <BsGithub className="text-xl" />
                <p className="text-xl">Github</p>
              </a>

              <a href="https://replit.com/@MyselfRipon" target={"_blank"} rel="noreferrer" className="text-amber-600 hover:text-white flex items-center justify-center gap-x-3 my-4 ">
                <SiReplit className="text-xl" />
                <p className="text-xl">Replit</p>
              </a>
            </div>
            <p className="text-center text-slate-800 text-base font-Nunito">
              View live project code on Replit.
            </p>
          </div>
        </div>
      </div>
      <Footer />
      {/* <Outlet/> url('Images/startman.avif') */}
    </>
  );
}

export default Contact;
