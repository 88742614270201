import React from "react";
import { useState, useEffect } from "react";

function Clock() {
  const [date, setDate] = useState(new Date());

  const refreshClock = () => {
    setDate(new Date());
  }

  const Hours = date.getHours();
  let cHours = ((Hours + 11) % 12 + 1)
  let cMinutes = date.getMinutes();
  let cSeconds = date.getSeconds();
  const suffix =  Hours >= 12 ? "PM":"AM";
  
  

  useEffect(() => {
    const timerId = setInterval(() => {
      refreshClock();
    }, 1000);;
  
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);


  return (
    <>
    <div>
      <p className="text-black dark:text-slate-200 font-Faster font-semibold text-base lg:text-4xl lg:font-bold dark:font-semibold uppercase p-3 mt-3 lg:p-10 lg:mt-4 bg-gradient-to-r from-pink-400 to-indigo-500 dark:bg-bg-gradient-to-r dark:from-[#191830] dark:to-[#252555]">Time is Ticking...</p>
      <p className="text-slate-100 font-semibold font-Nunito text-xs lg:text-2xl capitalize px-3 lg:px-10 bg-gradient-to-r from-pink-400 to-indigo-500 dark:bg-bg-gradient-to-r dark:from-[#191830] dark:to-[#252555]">If you spend too much time at thinking, you'll never get it done. go and get your work done.</p>
    </div>
    <div className="w-full py-14 mb-3 lg:w-[100%] flex justify-center items-center bg-gradient-to-r from-pink-400 to-indigo-500 dark:bg-bg-gradient-to-r dark:from-[#191830] dark:to-[#252555] lg:py-10 lg:mb-4">
        <div className="circle1 w-12 h-12 md:w-24 md:h-24 lg:w-64 lg:h-64 bg-gradient-to-r from-[#FF512F] to-[#F09819] rounded-full  lg:mr-24 translate-y-16 lg:translate-y-32 animate-wiggleDown3 lg:animate-wiggleDown"></div>
        <div className="circle2 w-16 h-16 md:w-28 md:h-28 lg:w-80 lg:h-80 bg-gradient-to-r from-[#02ccfc] to-[#0a2dbb] rounded-full mb-8 ml-48 md:mb-28 md:ml-[15rem] lg:mb-[28rem] lg:ml-[30rem] animate-wiggleDown4 lg:animate-wiggleDown2 "></div>
        <div className="glassCard min-w-[10px] min-h-[10px] max-w-sm max-h-28 lg:max-h-96 lg:max-w-5xl md:max-w-2xl md:max-h-64 w-full h-full shadow-2xl absolute rounded-md lg:rounded-3xl flex justify-center items-center bg-[#ffffff0b] backdrop-blur-xl dark:border dark:border-gray-700">
          <div className="hour-card w-16 h-16 md:w-24 md:h-24 lg:w-48 lg:h-48 backdrop-blur-3xl rounded-xl shadow-xl bg-[#ffffff1a] mx-2 lg:mx-4 flex items-center justify-center dark:border dark:border-gray-500">
            <p
              className="font-Lato text-white font-bold text-xl md:text-2xl lg:text-8xl"
              id="hours"
            >{cHours}</p>
          </div>

          <div className="w-10 h-20 lg:w-10 lg:h-48 text-white font-bold text-2xl lg:text-8xl flex items-center justify-center">:</div>

          <div className="minute-card  w-16 h-16 md:w-24 md:h-24 lg:w-48 lg:h-48 backdrop-blur-3xl rounded-xl shadow-xl bg-[#ffffff1a] mx-2 lg:mx-4 flex items-center justify-center dark:border dark:border-gray-500">
            <p
              className="font-Lato text-white font-bold text-xl md:text-2xl lg:text-8xl"
              id="minutes"
            >{cMinutes}</p>
          </div>
          
          <div className="w-10 h-20 lg:w-10 lg:h-48 text-white font-bold text-2xl lg:text-8xl flex items-center justify-center">:</div>

          <div className="second-card  w-16 h-16 md:w-24 md:h-24 lg:w-48 lg:h-48 backdrop-blur-3xl rounded-xl shadow-xl bg-[#ffffff1a] mx-2 lg:mx-4 flex items-center justify-center dark:border dark:border-gray-500">
            <p
              className="font-Lato text-[#f9d226] font-bold text-xl md:text-2xl lg:text-8xl"
              id="seconds"
            >{cSeconds}</p>
          </div>
          <div className="w-10 h-10 md:w-16 md:h-16 lg:w-32 lg:h-32 backdrop-blur-3xl rounded-xl shadow-xl bg-[#ffffff1a] lg:mx-4 flex items-center justify-center dark:border dark:border-gray-500">
            <p
              className="font-bold m-2 lg:m-5 font-Lato text-xs md:text-xl lg:text-4xl text-red-400"
              id="meridian"
            >{suffix}</p>
          </div>
        </div>
    </div>
    </>
  );
}

export default Clock;
