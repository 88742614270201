import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import ProjectBox from "../Components/ProjectBox";
import EditorX from "../Images/editorx.png";
import Cloudsphare from "../Images/Cloudsphare.jpg";
import News101 from "../Images/News101.png";
import WeTalk from "../Images/wetalk.png";
import Alerm from "../Images/clock.jpg";
import Calculator from "../Images/calculator.png";
import PDF from "../Images/pdf.jpg";
import Password from "../Images/passwordgen.jpg";

function Utilities() {
  const colorEffect = () => {
    document.getElementById("utility").style.color = "#050bab";
  };
  const colorNone = () => {
    document.getElementById("utility").style.color = "white";
  };
  return (
    <div>
      <div className="w-full h-[120vh] lg:h-screen">
        <div className="h-[120vh] lg:h-screen w-full bg-cover m-auto py-14 bg-[url('Images/UtilityBG.avif')] bg-no-repeat">
          <div
            className="lg:w-[50%] w-[85%] h-[15vh] mx-auto flex flex-col justify-center items-center bg-[#ffffff10] hover:bg-[#ffffff] transition-all ease-in shadow-2xl backdrop-blur-lg rounded-2xl border border-gray-400 hover:border-gray-700"
            onMouseOver={colorEffect}
            onMouseLeave={colorNone}
          >
            <h1
              id="utility"
              className="text-xl lg:text-3xl text-white text-center transition-all ease-in font-Nunito font-bold py-2"
            >
              Utility-Web App Projects
            </h1>
            <p className="text-sm text-center text-green-300 hover:text-green-500 px-3">
            Utilities are unavailable right now. For more Web Development projetcs please
              <Link to={"/webdevelopment"}>
                <u> check this. </u>
              </Link>
               These projects will be available in January 2023.
            </p>
          </div>

          <div className="w-[90%] lg:w-[80%] mx-auto mt-12 flex justify-center gap-x-4 gap-y-6 flex-wrap">
                <ProjectBox projectName="EditorX" logosrc={EditorX} type="Utility - Text Editor"/>
                <ProjectBox projectName="Cloudsphare" logosrc={Cloudsphare} type="Utility -Weather App"/>
                <ProjectBox projectName="News101" logosrc={News101} type="Utility - News App"/>
                <ProjectBox projectName="WeTalk" logosrc={WeTalk} type="Utilty - Chat App"/>
                <ProjectBox projectName="Alerm Clock" logosrc={Alerm} type="Utility"/>
                <ProjectBox projectName="Calculator" logosrc={Calculator} type="Utility"/>
                <ProjectBox projectName="Pdf manager" logosrc={PDF} type="Utility - Pdf manager"/>
                <ProjectBox projectName="Password Generator" logosrc={Password} type="Utility"/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Utilities;
