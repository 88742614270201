import React from "react";
import Footer from "../Components/Footer";

function AboutProject() {
  return (
    <>
      <div className="w-full h-full lg:h-screen">
        <div className="hidden md:hidden lg:block w-full h-full lg:h-[100vh] bg-cover m-auto py-14 bg-[url('Images/Bgnight.png')] bg-no-repeat saturate-100">
          <div className="w-[60%] h-[60vh] m-auto bg-[#ffffff10] shadow-2xl backdrop-blur-lg rounded-xl border border-gray-400">
            <h1 className="lg:text-3xl text-white font-Nunito font-bold text-center py-2">
              About This Project
            </h1>
            <hr className="mx-3 lg:mx-6 my-1" />
            <div className="mx-3 lg:mx-6 my-1 py-2 lg:py-4 font-Nunito text-gray-300 lg:text-base">
              <p className="my-3">
                Hello! Awesome people, I want go through the process of building
                this project. I want to give you a quick summary of how this
                website was made and the technolgies which are uses in this
                website.
              </p>
              <p className="my-3">
                <strong>Ritsux.Coder: </strong>This is my personal portfolio
                website where I can showcase my works in various sections. I
                have built this website using React JS & modern CSS framework
                Tailwind CSS which are absolutely stunning technolgies and Node
                Js in backend.
              </p>
              <p className="my-3">
                This website contains many different sections where you can show
                my work in different field like UX Design, Graphic Design,
                Clonning projets, Games, Simple layout website with some
                Utilities also. In game section, you can play multiple games
                also.
              </p>
              <p>
                I make it full responsive so that you can open it in many
                devices like Phone, Tablet or Desktop.
              </p>
              <p className="my-3">
                It was once my dream which I have been fulfill today. I Have
                applied what I have learned till date from various fields in
                this website and will apply more technolgies and creative things
                in future projects as well.
              </p>
              <p className="underline">
                {/* Need two point more... Images/AboutpSky.  */}
              </p>
            </div>
          </div>
        </div>

        {/* For Mobile Screen with differnt Background */}
        <div className=" w-full h-full bg-cover m-auto py-14 bg-[url('Images/AboutpSky.avif')] bg-no-repeat saturate-100 md:block lg:hidden">
          <div className="w-[80%] h-[70vh] m-auto bg-[#ffffff10] shadow-2xl backdrop-blur-lg rounded-xl border border-gray-400">
            <h1 className="text-lg text-white font-Nunito font-bold text-center py-2">
              About This Project
            </h1>
            <hr className="mx-3 my-1" />
            <div className="mx-3 my-1 py-2  font-Nunito text-gray-300 font- text-xs">
              <p className="my-3">
                Hello! Awesome people, I want go through the process of building
                this project. I want to give you a quick summary of how this
                website was made and the technolgies which are uses in this
                website.
              </p>
              <p className="my-3">
                <strong>Ritsux.Coder: </strong>This is my personal portfolio
                website where I can showcase my works in various sections. I
                have built this website using React JS & modern CSS framework
                Tailwind CSS which are absolutely stunning technolgies and Node
                Js in backend.
              </p>
              <p className="my-3">
                This website contains many different sections where you can show
                my work in different field like UX Design, Graphic Design,
                Clonning projets, Games, Simple layout website with some
                Utilities also. In game section, you can play multiple games
                also.
              </p>
              <p>
                I make it full responsive so that you can open it in many
                devices like Phone, Tablet or Desktop.
              </p>
              <p className="my-3">
                It was once my dream which I have been fulfill today. I Have
                applied what I have learned till date from various fields in
                this website and will apply more technolgies and creative things
                in future projects as well.
              </p>
              <p className="underline">
                {/* Need two point more... Images/AboutpSky.avif  */}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AboutProject;
