import React from 'react';
import { Link } from 'react-router-dom';


function WorksCard(props) {
  return (
    <div className=' lg:w-[10vw] bg-white px-3 py-2 mb-3 flex flex-col items-center lg:flex lg:flex-col lg:flex-wrap lg:items-center rounded-lg drop-shadow-xl dark:bg-slate-800 border dark:border-gray-700'>
        <img src={props.src} alt={props.alt} className={props.className} />
        <p className='text-center p-2 font-Aboutcard font-semibold text-[0.65rem] lg:text-sm xl:text-sm dark:text-white'>{props.title}</p>
        <button className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-600 to-indigo-500 group-hover:from-cyan-600 group-hover:to-indigo-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
            <Link to={props.link} className="relative text-xs lg:text-sm font-Caladea px-2 py-1 lg:px-3 lg:py-2 transition-all ease-in duration-75 bg-white dark:text-slate-400 hover:dark:text-white dark:bg-slate-900 rounded-md group-hover:bg-opacity-0">
                View Projects
            </Link>
        </button>
    </div>
  )
}

export default WorksCard;