import React from "react";
import webLayout from "../Images/webProject.png";
import game from "../Images/gameCard.png";
import utility from "../Images/utilityCard.png";
import clone from "../Images/Clone_projects.png";
import FuncCardMini from "./FuncCardMini";

function FunctionalCard() {
  return (
    <>
     {/* Laptop And Desktop Users  */}
    <div className="hidden md:hidden bg-white dark:bg-slate-800 lg:rounded-3xl lg:w-[20%] lg:flex lg:flex-col lg:justify-center lg:px-6 lg:py-4 shadow-2xl drop-shadow-md dark:border border-gray-700">
      <div>
        <h3 className="text-center bg-violet-500 px-2 py-1 text-white rounded-md font-bold text-xl font-lato">
          Explore Zone
        </h3>
      </div>
      <div>
        <FuncCardMini cardSrc={webLayout} btnText={"Websites"} pTitle ={"Web"} link="/websites"/>
        <FuncCardMini cardSrc={utility} btnText={"Utilities"} pTitle ={"Utility"} link="/utility"/>
        <FuncCardMini cardSrc={game} btnText={"Games"} pTitle ={"Game"} link="/games"/>
        <FuncCardMini cardSrc={clone} btnText={"Clones"} pTitle ={"Clone"} link="/projects"/>
      </div>
      <p className="text-center font-Nunito dark:text-slate-200">You can play games on Games section. 😍</p>
    </div>

     {/* For Mobile Devices  */}
    <div className="bg-white dark:bg-slate-700 rounded-lg w-[85%] m-auto flex flex-col justify-center px-6 py-4 lg:hidden">
      <div>
        <h3 className="text-center bg-violet-500 px-2 py-1 text-white rounded-md font-bold text-xl font-lato">
          Explore Zone
        </h3>
      </div>
      <div className="flex lg:hidden">
        <FuncCardMini cardSrc={webLayout} btnText={"Websites"} pTitle ={"Web"} link="/websites"/>
        <FuncCardMini cardSrc={utility} btnText={"Utilities"} pTitle ={"Utility"} link="/utility"/>
      </div>
      <div className="flex lg:hidden">
        <FuncCardMini cardSrc={game} btnText={"Games"} pTitle ={"Game"}/>
        <FuncCardMini cardSrc={clone} btnText={"Clones"} pTitle ={"Clone"}/>
      </div>
      <p className="text-center font-Nunito dark:text-slate-200">You can play games on Games section😍</p>
    </div>
    </>
  );
}

export default FunctionalCard;
