import React from "react";
import Footer from "../Components/Footer";

function Blog() {
  const colorEffect = () => {
    document.getElementById("blog").style.color = "#050bab";
  };
  const colorNone = () => {
    document.getElementById("blog").style.color = "white";
  };
  return (
    <div>
      <div className="w-full h-[75vh] lg:h-screen">
        {/* Large Screen */}
        <div className="hidden md:hidden lg:block w-full h-[100vh] bg-cover m-auto py-20 bg-[url('Images/blog.avif')] bg-no-repeat saturate-100">
          <div className="w-[50%] h-[20vh] m-auto flex justify-center items-center bg-[#ffffff10] hover:bg-[#ffffff] transition-all ease-in shadow-2xl backdrop-blur-lg rounded-2xl border border-gray-400 hover:border-gray-700" onMouseOver={colorEffect} onMouseLeave={colorNone}>
          <h1 id="blog" className="text-3xl text-white  transition-all ease-in font-Nunito font-bold py-2">
              Blog will be uploaded very soon...
            </h1>
          </div>
        </div>

        {/* Small Screen Codingblog*/}
        <div className="md:block lg:hidden w-full h-[75vh] bg-cover m-auto pt-32 bg-[url('Images/Codingblog.avif')] bg-no-repeat saturate-100">
          <div className="w-[80%] h-[20vh] m-auto flex justify-center items-center bg-[#ffffff10] hover:bg-slate-400  transition-all ease-in shadow-2xl backdrop-blur-lg rounded-2xl border border-gray-400 hover:border-gray-700" onMouseOver={colorEffect} onMouseLeave={colorNone}>
          <h1 id="blog" className="text-2xl text-white hover:text-slate-900 text-center transition-all ease-in font-Nunito font-bold py-2 px-3">
              Blog will be uploaded very soon...
            </h1>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Blog;
