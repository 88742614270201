import React from 'react'
import Footer from '../Components/Footer';
import Resume from '../Components/Resume'

function Resumepage() {
  return (
    <>
    <div className="my-6 mx-3 ">
      <Resume/>
    </div>
    <Footer/>
    </>
  )
}

export default Resumepage;