import React from "react";
import About from "../Components/About";
import Branding from "../Components/Branding";
import Clock from "../Components/Clock";
import FunctionalCard from "../Components/FunctionalCard";
import WorkDetails from "../Components/WorkDetails";
import Resume from "../Components/Resume";
import EthicSlider from "../Components/EthicSlider";
import QuoteSlider from "../Components/QuoteSlider";
import Service from "../Components/Service";
import Footer from "../Components/Footer";
import ViewResume from "../Components/ViewResume";

function Home() {

  return (
    <div className="dark:bg-slate-900">
    {/* For any Mobile & tablet Devices  */}
      <div className="lg:hidden flex justify-center mb-10 md:mt-16 w-full lg:my-6 lg:w-[85%] lg:px-8 lg:py-10 m-auto">
        <Branding />
      </div>
      <div className="mb-5 flex justify-center lg:hidden">
        <About/>
      </div>
      <div className="m-auto px-1 lg:hidden md:hidden">
        <ViewResume/>
      </div>
      <div className="m-auto lg:hidden">
        <WorkDetails/>
      </div>
  
      {/* For any lg Devices  */}
      <div className="hidden lg:flex lg:justify-around lg:w-[85%] lg:px-8 lg:py-10 m-auto">
        <div>
          <Branding />
          <About />
        </div>
        <div className="hidden lg:flex lg:flex-col">
          <WorkDetails />
        </div>
      </div>

      <div className="w-[85%] m-auto lg:hidden">
        <EthicSlider/>
      </div>
      <div className="hidden md:hidden lg:w-[85%] lg:px-8 lg:py-10 lg:m-auto lg:flex lg:justify-between">
        <EthicSlider/>
        <QuoteSlider/>
      </div>

      <Clock />
      {/* Mobile Phones */}
      <div className="w-[85%] m-auto lg:hidden my-4">
        <Service/>
      </div>

      <div className="lg:hidden">
        <FunctionalCard />
      </div>

      <div className="hidden lg:my-10 lg:flex lg:justify-between lg:w-[85%] lg:px-8 lg:py-10 m-auto ">
        <FunctionalCard />
        <Resume/>
      </div>

      <div className="w-[85%] my-5 px-1 lg:px-8 lg:py-10 m-auto flex justify-between lg:hidden">
      <QuoteSlider/>
      </div>

      <div className="hidden md:hidden lg:block lg:w-[85%] lg:m-auto lg:px-6 lg:my-4">
        <Service />
      </div>

      <Footer/>
    </div>
  );
}

export default Home;
