import React from "react";
import Footer from "../Components/Footer";

function Services() {
  return (
    <>
    {/* Large Screen */}
      <div className="w-full h-screen">
        <div className="hidden md:hidden lg:block w-full h-[100vh] bg-cover m-auto py-20 bg-[url('Images/Treeonlake.avif')] bg-no-repeat saturate-100">
          <div className="w-[80%] h-[80vh] m-auto py-10 bg-[#ffffff10] shadow-2xl backdrop-blur-sm rounded-xl border border-gray-400">
            <h1 className="text-3xl text-white font-Nunito font-bold text-center py-2">
              Services That I Provide
            </h1>
            <hr className="mx-6 my-1" />
            <div className="flex flex-col gap-y-8 mt-24 items-center font-Nunito text-lg font-semibold text-pink-600">
              <div className="bg-[#ffffff] w-[40rem] text-center px-8 py-5 rounded-md capitalize hover:bg-[#f5f5fd] cursor-pointer dark:bg-blue-800 hover:dark:bg-blue-900 dark:text-slate-200">
                Front end development with React JS, Next JS & Tailwind CSS
              </div>
              <div className="bg-[#ffffff] w-[40rem] text-center px-8 py-5 rounded-md capitalize hover:bg-[#f5f5fd] cursor-pointer dark:bg-blue-800 hover:dark:bg-blue-900 dark:text-slate-200">
                Graphic Design - It is done by me & my partnar together
              </div>
              <div className="bg-[#ffffff] w-[40rem] text-center px-8 py-5 rounded-md capitalize hover:bg-[#f5f5fd] cursor-pointer dark:bg-blue-800 hover:dark:bg-blue-900 dark:text-slate-200">
                UI/UX Design for mobile apps and websites
              </div>
            </div>
          </div>
        </div>

        {/* Small Screen */}
        <div className=" md:block lg:hidden w-full h-full bg-cover m-auto pt-28 bg-[url('Images/Treeonlake.avif')] bg-no-repeat saturate-100">
          <div className="w-[85%] h-[50vh] m-auto py-8 bg-[#ffffff10] shadow-2xl backdrop-blur-sm rounded-xl border border-gray-400">
            <h1 className="text-xl text-white font-Nunito font-bold text-center py-2">
              Services That I Provide
            </h1>
            <hr className="mx-5 my-1" />
            <div className="flex flex-col gap-y-5 md:gap-y-8 mt-10 md:mt-14 items-center font-Nunito md:text-2xl text-lg font-semibold text-pink-600">
              <div className="bg-[#ffffff] w-72 md:w-[28rem] text-center text-xs md:text-sm md:py-4 px-2 py-3 rounded-sm capitalize hover:bg-[#f5f5fd] cursor-pointer dark:bg-blue-800 hover:dark:bg-blue-900 dark:text-slate-200">
                Front end development with React JS, Next JS & Tailwind CSS
              </div>
              <div className="bg-[#ffffff] w-72 md:w-[28rem] text-center text-xs md:text-sm md:py-4 px-4 py-3 rounded-sm capitalize hover:bg-[#f5f5fd] cursor-pointer dark:bg-blue-800 hover:dark:bg-blue-900 dark:text-slate-200">
                Graphic Design - It is done by me & my partner together
              </div>
              <div className="bg-[#ffffff] w-72 md:w-[28rem] text-center text-xs md:text-sm md:py-4 px-4 py-3 rounded-sm capitalize hover:bg-[#f5f5fd] cursor-pointer dark:bg-blue-800 hover:dark:bg-blue-900 dark:text-slate-200">
                UI/UX Design for mobile apps and websites
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Services;
