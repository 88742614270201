import React from "react";
import { BiLinkExternal} from "react-icons/bi";

function ProjectBox(props) {
  return (
    <div className="h-40 w-44 lg:h-56 lg:w-64 bg-white flex flex-col justify-center items-center rounded-xl dark:bg-gradient-to-r dark:from-[#252555] dark:to-emerald-500 dark:border dark:border-gray-700 drop-shadow-lg">
      <img className="w-10 lg:w-14" src={props.logosrc} alt="Sk Ripon - Web Developer" />
      <h1 className="lg:text-2xl text-sm text-orange-500 dark:text-amber-300 font-Lato text-center font-semibold lg:px-5 mt-3 mb-1">
        {props.projectName}
      </h1>
      <p className="text-xs lg:text-sm mb-1 font-Nunito font-medium dark:text-slate-100">{props.type}</p>
      <p className="text-xs lg:text-sm my-1 font-PlayfairD underline text-pink-700 dark:text-green-200">{props.present}</p>
      <a href={props.href}
        target="_blank" without rel="noreferrer"
        className="flex gap-x-1 items-center hover:cursor-pointer text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br dark:bg-gradient-to-r dark:from-green-300 dark:to-emerald-600 focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-xs lg:text-sm px-5 py-2.5 text-center mr-2 mb-2"
      >
        Check This <BiLinkExternal />
      </a>
    </div>
  );
}

export default ProjectBox;
