import  React, {useState, useEffect, useRef } from "react";
import ethicsCard1 from "../Images/myethics_1.png";
import ethicsCard2 from "../Images/myethics_2.png";
import ethicsCard3 from "../Images/myethics_3.png";
import ethicsCard4 from "../Images/myethics_4.png";

import { MdOutlineSkipNext, MdOutlineSkipPrevious } from "react-icons/md";

const featuredImages = [ethicsCard1, ethicsCard2, ethicsCard3, ethicsCard4];

let count = 0;
let slideInterval;

function EthicSlider() {
 

  const [currentIndex, setCurrentIndex] = useState(0);

  const slideRef = useRef();

    
  const removeAnimation = () => {
    slideRef.current.classList.remove('fade-anim');
  };


  useEffect(() => {
    const startSlider = () => {
      slideInterval = setInterval(() => {
      handleOnNextClick();
      }, 6000);
      
    };

    slideRef.current.addEventListener("animationend", removeAnimation);
    slideRef.current.addEventListener("mouseenter", pauseSlider);
    slideRef.current.addEventListener("mouseleave", startSlider);

    startSlider();

    // Clean off funcion
    return () => {
      pauseSlider();
    };

  }, []);
  
    
  const pauseSlider = () => {
    clearInterval(slideInterval);
  }
  

  const handleOnNextClick = () => {
    count = (count + 1) % featuredImages.length;
    setCurrentIndex(count);
    slideRef.current.class.add('fade-anim');
  };
  
  const handleOnPrevClick = () => {
    const imagesLength = featuredImages.length;
    count = (currentIndex + imagesLength - 1) % imagesLength;
    setCurrentIndex(count);
    slideRef.current.class.add('fade-anim');
  };
  

  return (
    <>
      <div className="w-full my-4 px-1 lg:w-[45%] ">
        <div className="w-full relative select-none" ref={slideRef}>
          <div >
            <img src={featuredImages[currentIndex]} alt="My Ethics on Slider" />
          </div>

          <div className="absolute w-full top-1/2 transform -translate-y-1/2 flex justify-between items-center px-3">
            <button onClick={handleOnPrevClick} className="bg-black text-white p-2 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition">
              <MdOutlineSkipPrevious/>
            </button>

            <button onClick={handleOnNextClick} className="bg-black text-white p-2 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition">
              <MdOutlineSkipNext/>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EthicSlider;
