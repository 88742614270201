import React from "react";
import { Link } from "react-router-dom";
import { MdCall, MdLocationPin } from "react-icons/md";
import { FaEnvelope, FaLinkedinIn, FaExternalLinkAlt } from "react-icons/fa";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { BsGithub } from "react-icons/bs";

function ResumeHead() {
  return (
    <>
      <div className="ResumeHead flex justify-between bg-[#0f2463] rounded-t-md lg;rounded-t-2xl py-2 px-2">
        <div className="contact-sec lg:text-xl  lg:px-4 font-Lato text-white w-[39%]">
          <h2 className="text-base md:text-lg lg:text-xl font-medium lg:font-semibold uppercase tracking-wider lg:tracking-widest border-b lg:border-b-2 py-1">
            Contact
          </h2>
          <div className="call flex items-center text-xs font-thin my-4">
            <MdCall />
            <p className="pl-1 text-[9px] md:text-xs lg:px-2">+91-8927929837</p>
          </div>
          <div className="email flex items-center text-xs font-thin my-4">
            <FaEnvelope />
            <p className="pl-1 text-[9px] md:text-xs lg:px-2">myselfripon20@gmail.com</p>
          </div>
          <div className="address flex items-center text-xs font-thin my-4">
            <MdLocationPin />
            <p className="pl-1 text-[9px] md:text-xs lg:px-2">Kolkata, West Bengal, India</p>
          </div>
          <div className="website flex items-center text-xs font-thin my-4">
            <GiEarthAsiaOceania />
            <Link to="/">
              <p className="px-1 text-[9px] md:text-xs lg:px-2 cursor-pointer hover:underline">
                https://skriponux.pages.dev
              </p>
            </Link>
            <FaExternalLinkAlt />
          </div>
          <div className="linkedin flex items-center text-xs font-thin my-2">
            <FaLinkedinIn />
            <a
              href="https://www.linkedin.com/in/sk-ripon-a82a7b222"
              target="_blank"
              rel="noreferrer"
            >
              <p className="px-1 text-[9px] md:text-xs lg:px-3 cursor-pointer hover:underline">Sk Ripon</p>
            </a>
            <FaExternalLinkAlt />
          </div>
          <div className="github flex items-center text-xs font-thin my-2">
            <BsGithub />
            <a
              href="https://github.com/MyselfRipon20"
              target="_blank"
              rel="noreferrer"
            >
              <p className="px-1 text-[9px] md:text-xs lg:px-3 cursor-pointer hover:underline">
                MyselfRipon20
              </p>
            </a>
            <FaExternalLinkAlt />
          </div>
        </div>


        <div className="name-sec text-white px-1 lg:px-4 w-[59%]">
          <h1 className="text-lg md:text-2xl lg:text-3xl font-PlayfairD font-bold lg:font-extrabold tracking-wider lg:tracking-widest mb-1 hover:text-yellow-200 hover:underline">
            SK RIPON
          </h1>
          <h6 className="text-xs md:text-base font-medium lg:text-lg lg:font-semibold font-lato border-b lg:border-b-2 py-1 mb-1">
            Front End Web Developer | UI/UX Designer
          </h6>
          <p className="my-[6px] md:my-2 lg:my-3 text-start text-[9px] md:text-xs lg:text-xs">
           Experienced front-end web developer with 9 months of experience in freelancing and 1.5+ years of hands-on practice experience building responsive and user-friendly websites and web applications. Proficient in HTML5, CSS3, JavaScript, Redux, and RESTful APIs, and CSS framework (Bootstrap & Tailwind CSS). Excellent problem-solving skills and a strong eye for design.
          </p>
          <p className="my-[6px] md:my-2 lg:my-3 text-start text-[9px] md:text-xs lg:text-xs">
            I'm also a freelance UI/UX Designer with 6 months of experience.
          </p>
          <p className="my-[6px] md:my-2 lg:my-3 text-start text-[9px] md:text-xs lg:text-xs">
            Too passionate about futuristic technology, psychology enthusiastic,
            a boy with a goal of becoming one among the best web developers.
          </p>
        </div>
      </div>
    </>
  );
}

export default ResumeHead;
