import React from "react";
import HSlogo from "../Images/HappySmilelogo.jpg";
import gymbros from "../Images/gymbro.png";
import EditorX from "../Images/editorx.png";
import Cloudsphare from "../Images/Cloudsphare.jpg";
import News101 from "../Images/News101.png";
import WeTalk from "../Images/wetalk.png";
import Lamborghini from "../Images/Lamborghini.png";
import GuesstheNumber from "../Images/GuesstheNumber.png";
import CuteSnake from "../Images/CuteSnake.png";
import Alerm from "../Images/clock.jpg";
import Calculator from "../Images/calculator.png";
import PDF from "../Images/pdf.jpg";
import Password from "../Images/passwordgen.jpg";
import iMusic from "../Images/imusic.jpg";
import Tictactoe from "../Images/Tictactoe.jpg";
import ProjectBox from "../Components/ProjectBox";
import Footer from "../Components/Footer";

function WebDevelopment() {
  const colorEffect = () => {
    document.getElementById("web").style.color = "#050bab";
  };
  const colorNone = () => {
    document.getElementById("web").style.color = "white";
  };
  return (
    <div>
      <div className="w-full lg:h-[180vh]">
        {/* Large Screen */}
        <div className="hidden md:hidden lg:block h-[180vh] w-full bg-cover m-auto py-14 bg-[url('Images/FrontEndbg.avif')] bg-no-repeat">
          <div
            className="w-[50%] h-[15vh] mx-auto flex flex-col justify-center items-center bg-[#ffffff10] hover:bg-[#ffffff] transition-all ease-in shadow-2xl backdrop-blur-lg rounded-2xl border border-gray-400 hover:border-gray-700"
            onMouseOver={colorEffect}
            onMouseLeave={colorNone}
          >
            <h1
              id="web"
              className="text-3xl text-white  transition-all ease-in font-Nunito font-bold py-2"
            >
              Web Development Projects 
            </h1>
            <p className="text-sm text-amber-300 hover:text-amber-500">For more Web Development projetcs please check <u>Explore Zone</u> in Home</p>
          </div>

          <div className="w-[80%] mx-auto mt-12 flex justify-center gap-8 flex-wrap">
              <ProjectBox projectName="Happy Smile Dental" logosrc={HSlogo} type="Website" present="Available Now" href="https://happysmile.pages.dev"/>
              <ProjectBox projectName="Gym Bros" logosrc={gymbros} type="Website" present="Available Now" href="https://gymbros.pages.dev/"/>
              <ProjectBox projectName="EditorX" logosrc={EditorX} type="Utility - Text Editor"/>
              <ProjectBox projectName="Cloudsphare" logosrc={Cloudsphare} type="Utility -Weather App"/>
              <ProjectBox projectName="News101" logosrc={News101} type="Utility - News App"/>
              <ProjectBox projectName="WeTalk" logosrc={WeTalk} type="Utilty - Chat App"/>
              <ProjectBox projectName="Lambo Racer" logosrc={Lamborghini} type="Game"/>
              <ProjectBox projectName="Guess The Number" logosrc={GuesstheNumber} type="Game"/>
              <ProjectBox projectName="CuteSnake" logosrc={CuteSnake} type="Game"/>
              <ProjectBox projectName="Alerm Clock" logosrc={Alerm} type="Utility"/>
              <ProjectBox projectName="Calculator" logosrc={Calculator} type="Utility"/>
              <ProjectBox projectName="Pdf manager" logosrc={PDF} type="Utility - Pdf manager"/>
              <ProjectBox projectName="Password Generator" logosrc={Password} type="Utility"/>
              <ProjectBox projectName="iMusic" logosrc={iMusic} type="Utilty - Music App"/>
              <ProjectBox projectName="Tic Tac Toe" logosrc={Tictactoe} type="Game"/>
          </div>
        </div>

        {/* Small Screen Codingblog*/}
        <div className="md:block lg:hidden h-[180vh] w-full m-auto pt-16 bg-[url('Images/project_bg.avif')] saturate-100">
          <div
            className="w-[85%] h-[12vh] mx-auto my-8 flex justify-center flex-col items-center bg-[#ffffff10] transition-all ease-in shadow-2xl backdrop-blur-lg rounded-2xl border border-gray-400 hover:border-gray-700"
            onMouseOver={colorEffect}
            onMouseLeave={colorNone}
          >
            <h1
              
              className="text-xl text-amber-300 text-center transition-all ease-in font-Nunito font-bold py-2 px-3"
            >
             Web Development Projects 
            </h1>
            <p className="text-xs text-white text-center px-3">For more Web Development projetcs please check <u>Explore Zone</u> in Home</p>
          </div>

          <div className="flex flex-wrap justify-center gap-4">
              <ProjectBox projectName="Happy Smile Dental" logosrc={HSlogo} type="Website" present="Available Now" href="https://happysmile.pages.dev/"/>
              <ProjectBox projectName="EditorX" logosrc={EditorX} type="Utility - Text Editor"/>
              <ProjectBox projectName="Cloudsphare" logosrc={Cloudsphare} type="Utility -Weather App"/>
              <ProjectBox projectName="News101" logosrc={News101} type="Utility - News App"/>
              <ProjectBox projectName="WeTalk" logosrc={WeTalk} type="Utilty - Chat App"/>
              <ProjectBox projectName="Lambo Racer" logosrc={Lamborghini} type="Game"/>
              <ProjectBox projectName="Guess the number" logosrc={GuesstheNumber} type="Utility - Web App"/>
              <ProjectBox projectName="CuteSnake" logosrc={CuteSnake} type="Game"/>
              <ProjectBox projectName="Alerm Clock" logosrc={Alerm} type="Utility"/>
              <ProjectBox projectName="Calculator" logosrc={Calculator} type="Utility"/>
              <ProjectBox projectName="Pdf manager" logosrc={PDF} type="Utility"/>
              <ProjectBox projectName="Password Generator" logosrc={Password} type="Utility"/>
              <ProjectBox projectName="iMusic" logosrc={iMusic} type="Utilty - Music App"/>
              <ProjectBox projectName="Tic Tac Toe" logosrc={Tictactoe} type="Game"/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default WebDevelopment;
