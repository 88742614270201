import  React, {useState } from "react";
// import  {useEffect, useRef } from "react";
import QoutesCard1 from "../Images/QoutesCard1.png";
import QoutesCard2 from "../Images/QoutesCard2.png";
import QoutesCard3 from "../Images/QoutesCard3.png";
import QoutesCard4 from "../Images/QoutesCard4.png";

import { MdOutlineSkipNext, MdOutlineSkipPrevious } from "react-icons/md";

const quotesImages = [QoutesCard1, QoutesCard2, QoutesCard3, QoutesCard4];

let count = 0;
// let slideInterval;

function QuoteSlider() {
 

  const [currentIndex, setCurrentIndex] = useState(0);

  // const slideRef = useRef();

    
  // const removeAnimation = () => {
  //   slideRef.current.classList.remove('fade-anim');
  // };


  // useEffect(() => {
  //   const startSlider = () => {
  //     slideInterval = setInterval(() => {
  //     handleOnNextClick();
  //     }, 3000);
      
  //   };

  //   // slideRef.current.addEventListener("animationend", removeAnimation);
  //   slideRef.current.addEventListener("mouseenter", pauseSlider);
  //   slideRef.current.addEventListener("mouseleave", startSlider);

  //   startSlider();

  //   // Clean off funcion
  //   return () => {
  //     pauseSlider();
  //   };

  // }, []);
  
    
  // const pauseSlider = () => {
  //   clearInterval(slideInterval);
  // }
  

  const handleOnNextClick = () => {
    count = (count + 1) % quotesImages.length;
    setCurrentIndex(count);
    // slideRef.current.classList.add('fade-anim');
  };
  
  const handleOnPrevClick = () => {
    const imagesLength = quotesImages.length;
    count = (currentIndex + imagesLength - 1) % imagesLength;
    setCurrentIndex(count);
    // slideRef.current.classList.add('fade-anim');
  };
  

  return (
    <>
      <div className="w-full my-4 px-1 lg:w-[45%]">
        <div className="w-full relative select-none">
          <div >
            <img src={quotesImages[currentIndex]} alt="My Ethics on Slider" />
          </div>

          <div className="absolute w-full top-1/2 transform -translate-y-1/2 flex justify-between items-center px-3">
            <button onClick={handleOnPrevClick} className="bg-black text-white p-2 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition">
              <MdOutlineSkipPrevious/>
            </button>

            <button onClick={handleOnNextClick} className="bg-black text-white p-2 rounded-full bg-opacity-50 cursor-pointer hover:bg-opacity-100 transition">
              <MdOutlineSkipNext/>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuoteSlider;
