import  React from "react";
// import {useEffect, useRef  } from "react";
import WebS from "../Images/SlideWeb2.png";
import UXS from "../Images/SlideUX2.png";
import GraphicS from "../Images/SlideGraphic2.png";



function Service() {

    return (
      <>
       <div><h3 className="text-xl md:text-2xl lg:text-4xl text-black dark:text-slate-100 font-bold my-2 text-center font-serif">My Services</h3></div>
        <div className="md:hidden w-full border dark:border-gray-600 rounded-md lg:rounded-[21px] lg:flex mx-3">
          <marquee width="100%" direction="left" behavior="scroll" scrollamount="12">
            <img className="h-24 lg:h-52" src={WebS} alt="Web Development" />
          </marquee>
          <marquee width="100%" direction="left" behavior="scroll" scrollamount="12">
            <img className="h-24 lg:h-52" src={UXS} alt="Web Development" />
          </marquee>
          <marquee width="100%" direction="left" behavior="scroll" scrollamount="12">
            <img className="h-24 lg:h-52" src={GraphicS} alt="Web Development" />
          </marquee>
        </div>
      </>
    );
  }
  
  export default Service;

  // <img src={UXS} alt="UX Design" /> <img src={GraphicS} alt="Graphic design" />