import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import ProjectBox from "../Components/ProjectBox";
import HSlogo from "../Images/HappySmilelogo.jpg";

function Website() {
    const colorEffect = () => {
        document.getElementById("web").style.color = "#050bab";
      };
      const colorNone = () => {
        document.getElementById("web").style.color = "white";
      };
  return (
    <div>
      <div className="w-full h-screen">
        <div className="h-screen w-full bg-cover m-auto py-14 bg-[url('Images/websitebg.avif')] bg-no-repeat">
        <div
            className="lg:w-[50%] w-[85%] h-[15vh] px-3 mx-auto flex flex-col justify-center items-center bg-[#ffffff10] hover:bg-[#ffffff] transition-all ease-in shadow-2xl backdrop-blur-lg rounded-2xl border border-gray-400 hover:border-gray-700"
            onMouseOver={colorEffect}
            onMouseLeave={colorNone}
          >
            <h1
              id="web"
              className="text-xl lg:text-3xl text-white text-center transition-all ease-in font-Nunito font-bold py-2"
            >
              Website/Layout Projects
            </h1>
            <p className="text-sm text-center text-green-300 hover:text-green-500 px-3">
            Utilities are unavailable right now. For more Web Development projetcs please
              <Link to={"/webdevelopment"}>
                <u> check this. </u>
              </Link>
               These projects will be available in January 2023.
            </p>
          </div>

          <div className="w-[80%] mx-auto mt-12 flex justify-center gap-8 flex-wrap">
            <ProjectBox projectName="Happy Smile Dental" logosrc={HSlogo} type="Website" present="Available Now" href="https://myselfripon20.github.io/happysmiledental/"/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Website;
