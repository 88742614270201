import React from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function About() {
  const hoverEffect = () => {
    document.getElementById("aboutHeader").style.textDecoration = "underline";
  };
  const hoverNone = () => {
    document.getElementById("aboutHeader").style.textDecoration = "none";
  };

  return (
    <div
      className="w-[85%] px-3 py-4 lg:w-[63vw] lg:px-10 lg:py-16 bg-gradient-to-br from-white to-[#e6ecfe] dark:bg-bg-gradient-to-r dark:from-black dark:to-[#252555] dark:border dark:border-gray-700 drop-shadow-lg rounded-[1.25rem] transition-all ease-in duration-1000 hover:bg-gradient-to-tl "
      id="about"
      onMouseEnter={hoverEffect}
      onMouseLeave={hoverNone}
    >
      <h2
        className="text-lg font-semibold mb-2 lg:text-3xl lg:font-bold lg:mb-7 font-PlayfairD dark:text-slate-200"
        id="aboutHeader"
      >
        About Myself
      </h2>
      <p className="text-sm mb-2 lg:mb-3 lg:text-lg text-gray-800 dark:text-slate-300 font-Nunito">
        Hello awesome people! Good to see you here. This page is a quick of
        summary of what I've been upto.
      </p>
      <hr className="my-1" />
      <p className="text-xs mb-2 lg:mb-3 lg:text-lg text-gray-700 dark:text-slate-400 font-Nunito">
        <strong>
          I am a Front end web developer and a UI/UX designer who love to design
          websites and apps and give them life by coding.
        </strong>
        Though my background is medical but a strong passion for technology and
        computers bring me into this field.
      </p>
      <p className="text-xs mb-2 lg:mb-3 lg:text-lg text-gray-700 dark:text-slate-400 font-Nunito">
        And I believe if you have a strong reason to do something and if you are
        truely passionate about something then learning code is just a little
        thing, you can do anything, you can learn anything.
      </p>
      <p className="text-xs mb-2 lg:mb-3 lg:text-lg text-gray-700 dark:text-slate-400 font-Nunito">
        I love to read books, listen podcast, business talks and read a lot
        about human psycology and behavior.
      </p>
      <button
        type="button"
        className="flex items-center gap-x-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 dark:bg-gradient-to-r dark:from-blue-700 dark:via-blue-800 dark:to-blue-900 hover:bg-gradient-to-br dark:hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 my-3"
      >
        <Link to="/about">About This Project</Link> <BsFillArrowRightCircleFill/>
      </button>
    </div>
  );
}

export default About;
