import React from 'react';
import {Outlet, Link} from "react-router-dom";
import logo from "../Images/Logo.png";
import { FaBars } from "react-icons/fa";
import { useState } from 'react';


function Navbar() {
  
   let [open, setOpen] = useState(false);

  return (
    <>
     <nav className='hidden md:hidden bg-[#06063e] text-white lg:flex justify-between px-3 sticky z-10 top-0 opacity-90 shadow-lg '>
        <div className='flex items-center'>
          <img src={logo} alt="logo" className='w-9 mx-1 md:w-14 md:mx-3'/>
          <span className='font-LogoF text-xs md:text-xl'>Hello! I am Sk Ripon 🙋‍♂️❤️</span>
        </div>
        <div className='lg:flex lg:items-center'>
          <ul className='lg:flex lg:flex-row text-sm md:gap-x-6 md:py-4 md:px-6  md:mr-8 font-Nunito md:text-xl'>
            <li className='lg:block xl:block  hover:underline hover:text-slate-300'>
              <Link to="/">Home</Link>
            </li>
            <li className='lg:block xl:block  hover:underline hover:text-slate-300'>
              <Link to="/contact">Contact</Link>
            </li>
            <li className='lg:block xl:block  hover:underline hover:text-slate-300'>
              <Link to="/services">Services</Link>
            </li>
            <li className='lg:block xl:block  hover:underline hover:text-slate-300'>
              <Link to="/blog">Blog</Link>
            </li>
            <li className='lg:block xl:block hover:underline hover:text-blue-300'>
              <Link to="/about">About This Project</Link>
            </li>
          </ul>
          <FaBars className='cursor-pointer mx-3 md:text-xl lg:hidden'/>
       </div>
      </nav>

      {/* Specifically Mobile & Medium screen */}
     <nav className='lg:hidden w-full bg-[#06063e] text-white px-3 fixed z-10 top-0 left-0 shadow-lg opacity-95'>
        <div className='flex items-center'>
          <img src={logo} alt="logo" className='w-9 mx-1 md:w-14 md:mx-3'/>
          <span className='font-LogoF text-xs md:text-xl'>Hello! I am Sk Ripon 🙋‍♂️❤️</span>
        </div>
        <div onClick={()=>setOpen(!open)} className='text-3xl absolute right-5 top-2 cursor-pointer text-white transition-all duration-75 ease-in'>
          <ion-icon name={open ? 'close':'menu'}></ion-icon>
        </div>
        <div className='flex items-center'>
          <ul className={`flex flex-col items-center gap-y-6 px-4 py-6 absolute md:z-auto w-full md:w-auto left-0 bg-blue-900 text-white text-sm md:gap-x-6 md:py-4 md:px-6 md:mr-8 font-Nunito md:text-xl transition-all duration-100 ease-in ${open ? 'top-11':'top-[-400px]'}`}>
            <li className='hover:underline hover:text-slate-300'>
              <Link to="/">Home</Link>
            </li>
            <li className='hover:underline hover:text-slate-300'>
              <Link to="/contact">Contact</Link>
            </li>
            <li className='hover:underline hover:text-slate-300'>
              <Link to="/services">Services</Link>
            </li>
            <li className='hover:underline hover:text-slate-300'>
              <Link to="/blog">Blog</Link>
            </li>
            <li className='hover:underline hover:text-green-300'>
              <Link to="/about">About This Project</Link>
            </li>
          </ul>
          {/* <FaBars className='cursor-pointer mx-3 md:text-xl lg:hidden'/> */}
       </div>
      </nav>
      <Outlet />
    </>
  )
}

export default Navbar;


// Dark mode 

// * <div className="toggleGrp flex items-center gap-x-2 mr-5 md:gap-x-3">
//               <span className='font-Faster text-xs md:hidden lg:hidden xl:hidden'>LIGHT ON</span>
//               <span className='hidden lg:block md:block xl:block font-Faster md:text-base lg:text-lg'>SWITCH LIGHT ON</span>
//               <label htmlFor="small-toggle" className="inline-flex relative items-center cursor-pointer md:hidden lg:hidden xl:hidden">
//                 <input type="checkbox" value="" id="small-toggle" className="sr-only peer"/>
//                 <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
//               </label>
//               <label htmlFor="blue-toggle" className="hidden relative items-center mr-5 cursor-pointer md:inline-flex lg:inline-flex">
//                 <input type="checkbox" value="" id="blue-toggle" className="sr-only peer"/>
//                 <div className="w-11 h-6 bg-yellow-300 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
//               </label>
//           </div> 