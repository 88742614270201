import React from 'react';
import WorksCard from './WorksCard';
import programmer from '../Images/programmer.png.jpg';
import uxDesigner from '../Images/UX Designer.jpg';
import uiDeveloper from '../Images/UI Developer.jpg';
import graphicDesigner from '../Images/Graphic Designer.jpg';

function WorkDetails() {
  return (
    <>
     <div className='w-[85%] m-auto flex items-center justify-between gap-x-4 md:hidden lg:hidden xl:hidden'>
        <WorksCard src={programmer} alt={"Front-end Web Development project"} title={`Front-End Web Developer`} className={"w-14"} link="/webdevelopment"/>
        <WorksCard src={uxDesigner} alt={"Ux Design Project"} title={"User Experience Designer"} className={"w-14"} link="/projects"/>
      </div>
      <div className='w-[85%] m-auto md:hidden lg:hidden xl:hidden '>
      <WorksCard src={uiDeveloper} alt={"UI Developer Projects"} title={"UI Developer"} className={"w-20"} link="/projects"/>
      <WorksCard src={graphicDesigner} alt={"Graphic Design Projects"} title={"Graphic Designer"} className={"w-20"} link="/projects"/>
      </div>

    {/* MD Brakpoints  */}
      <div className='hidden md:w-[85%] m-auto px-2 md:flex md:items-center md:justify-between gap-x-4 lg:hidden xl:hidden'>
        <WorksCard src={programmer} alt={"Front-end Web Development project"} title={`Front-End Web Developer`} className={"w-14"} link="/webdevelopment"/>
        <WorksCard src={uiDeveloper} alt={"UI Developer Projects"} title={"UI Developer"} className={"w-20"} link="/projects"/>
        <WorksCard src={uxDesigner} alt={"Ux Design Project"} title={"User Experience Designer"} className={"w-14"} link="/projects"/>
      </div>
      <div className='hidden md:block md:w-[85%] px-2 m-auto lg:hidden xl:hidden '>
      <WorksCard src={graphicDesigner} alt={"Graphic Design Projects"} title={"Graphic Designer"} className={"w-24"} link="/projects"/>
      </div>


       {/* lg breakpoints */}
      <div className='hidden lg:flex lg:items-center lg:flex-col lg:gap-y-3'>
        <WorksCard src={programmer} alt={"Front-end Web Development project"} title={"Front-End Web Developer"} className={"w-[4.2vw]"} link="/webdevelopment"/>
        <WorksCard src={uxDesigner} alt={"Ux Design Project"} title={"User Experience Designer"} className={"w-[3.7vw]"} link="/projects"/>
        <WorksCard src={uiDeveloper} alt={"UI Developer Projects"} title={"UI Developer"} className={"w-[5vw]"} link="/projects"/>
       <WorksCard src={graphicDesigner} alt={"Graphic Design Projects"} title={"Graphic Designer"} className={"w-[5.4vw]"} link="/projects"/>
      </div>
    </>
  );
}

export default WorkDetails;