import React from "react";
import uxbanner from "../Images/ritsux_banner.jpg";
import uxbannerDark from "../Images/ritsux_bannerdark.png";
import videoBG from "../videos/videoBG.mp4"
import ritsbg from "../videos/Rits_video.mp4"


function Branding() {
  return (
    <>
    {/* Small Devices  */}
      <div className="lg:hidden ">
        <div className="my-auto absolute top-10 left-36 md:left-[18rem]">
          <video src={ritsbg} alt="Sk Ripon web developer" className="w-1/2 lg:w-[10.5vw] mr-6 drop-shadow-md object-contain" autoPlay muted loop />
        </div>
        <video className="object-contain" src={videoBG} autoPlay muted loop />
        <div className="mx-auto text-center">
          <h5 className="text-xs md:text-lg text-center font-mono font-bold text-white relative bottom-8 md:bottom-20">
            React Js Developer | Front-end Developer | UX Designer
          </h5>
        </div>
        <img className="mt-2 w-[85%] m-auto rounded-md drop-shadow-md dark:hidden" src={uxbanner} alt="Sk Ripon web developer" />
        <img className="mt-2 w-[85%] m-auto rounded-md drop-shadow-md hidden dark:block" src={uxbannerDark} alt="Sk Ripon web developer" />
      </div>

     {/* Large Size Devices  */}
     <div className="hidden lg:flex mb-4 lg:mb-16"> 
     <video src={ritsbg} alt="Sk Ripon web developer" className="w-1/2 lg:w-[10.5vw] mr-6 drop-shadow-lg object-contain rounded-lg" autoPlay muted loop />
      <img src={uxbanner} alt="Sk Ripon web developer" className="w-[68vw] lg:w-[51vw] rounded-md lg:rounded-[1.25rem] drop-shadow-md dark:hidden"/>
      <img src={uxbannerDark} alt="Sk Ripon web developer" className="w-[68vw] lg:w-[51vw] rounded-md lg:rounded-[1.25rem] drop-shadow-md hidden dark:block"/>
     </div>
    </>
  );
}

export default Branding;
