import React from 'react';
import { Link } from 'react-router-dom';
import Code from "../Images/Codelogo.png";

function Footer() {
  return (
    <>
        <footer className="p-4 bg-[#06063e] shadow md:px-6 md:py-8">
            <div className="sm:flex sm:items-center md:justify-between lg:justify-between">
                <a href="/" className="justify-center flex items-center mb-4 sm:mb-0">
                    <img src={Code} className="mr-3 h-10" alt="Ritsux.coder"/>
                    <span className="self-center text-2xl text-white font-semibold whitespace-nowrap dark:text-white">Ritsux.coder</span>
                </a>
                <ul className="flex flex-wrap justify-center items-center lg:mb-6 text-sm text-gray-200 sm:mb-0 dark:text-gray-400">
                    <li>
                        <Link to="/about" className="mr-4 hover:underline md:mr-6 ">About This Project</Link>
                    </li>
                    <li>
                        <Link to="/privacypolicy" className="mr-4 hover:underline md:mr-6">Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to="/contact" className="hover:underline">Contact</Link>
                    </li>
                </ul>
            </div>
            <hr className="my-4 border-gray-400 sm:mx-auto dark:border-gray-700 lg:my-8"/>
            <span className="text-center block text-sm text-gray-200 sm:text-center dark:text-gray-400">© 2022 <a href="https://skriponux.pages.dev/" className="hover:text-amber-400 underline">
             skriponux.pages.dev </a>. Made With ❤️ In India. <a className='text-pink-400 underline hover:text-pink-500' href="https://www.instagram.com/ritsux.coder/" target="_blank" rel="noopener noreferrer">@Ritsux.coder</a> 
            </span>
        </footer>
    </>

  )
}

export default Footer;